import React, { useEffect, useState } from "react";
import ChatBox, { ChatFrame } from "react-chat-plugin";
import { SiChatbot } from "react-icons/si";
import axios from "axios";
import { BASE_URL } from "../constants";
import ChatBoxFooter from "./ChatBoxFooter";
import robotIconDataURL from "../assets/chatbot-images/bot-message.jpg";
import userIconDataURL from "../assets/chatbot-images/chatbox-user.png";

const Chat = ({ chatbotData, chatbotId, domain }) => {
  const [loading, setLoading] = useState(false);
  const { greetingsText, initialText, name, logo } = chatbotData;

  let botUrl = robotIconDataURL;
  if (logo.imgSrc) {
    botUrl = logo.imgSrc;
  }

  const [attr, setAttr] = useState({
    showChatbox: false,
    showIcon: true,
    messages: [
      {
        text: name || "SMART AI が会話に参加しました",
        timestamp: +new Date(),
        type: "notification",
      },
      {
        author: {
          username: name || "Ncc chatbot",
          id: 2,
          avatarUrl: botUrl, // Use React component for bot avatar
        },
        text: initialText || "Hi, how can I help you?",
        type: "text",
        timestamp: +new Date(),
      },
    ],
  });

  const handleClickIcon = () => {
    setAttr({
      ...attr,
      showChatbox: !attr.showChatbox,
      showIcon: !attr.showIcon,
    });
  };

  const handleOnSendMessage = async (message) => {
    // Add user message to chat
    const userMessage = {
      author: {
        username: "user",
        id: 1,
        avatarUrl: userIconDataURL, // Use React component for user avatar
      },
      text: message,
      type: "text",
      timestamp: +new Date(),
    };

    // Prepare the current state with the new message
    const newMessages = [...attr.messages, userMessage];
    setAttr({
      ...attr,
      messages: newMessages,
    });

    setLoading(true);
    // Send the updated messages (conversation history) to the server
    try {
      const response = await axios.post(BASE_URL + "/public/query", {
        message,
        chatbotId,
        conversation: newMessages,
        domain,
      });
      const reply = {
        author: {
          username: name || "Ncc chatbot",
          id: 2,
          avatarUrl: botUrl, // Use React component for bot avatar
        },
        text: response.data?.text || response.data.message,
        type: "text",
        timestamp: +new Date(),
      };
      // Update messages with the response from the server
      setAttr((attr) => ({
        ...attr,
        messages: [...attr.messages, reply],
      }));
    } catch (error) {
      console.log(" api limit error ", error.response);
      const rateLimitRemaining =
        error.response?.headers["x-ratelimit-remaining"];

      if (rateLimitRemaining <= 0) {
        const reply = {
          author: {
            username: "SMART AI",
            id: 2,
            avatarUrl: botUrl, // Use React component for bot avatar
          },
          text: "**You have finished all attempt, please try again later",
          type: "text",
          timestamp: +new Date(),
        };
        // Update messages with the response from the server
        setAttr((attr) => ({
          ...attr,
          messages: [...attr.messages, reply],
        }));
      }
      console.error("Failed to send/receive the message:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const iconContainers = document.getElementsByClassName(
      "react-chat-frame-icon-container"
    );
    if (iconContainers.length <= 0) return;
    if (logo?.imgSrc?.length > 0) {
      const iconContainers = document.getElementsByClassName(
        "react-chat-frame-icon-container"
      );
      for (let i = 0; i < iconContainers.length; i++) {
        iconContainers[i].style.background = "none";
        iconContainers[i].style.width = "auto";
        iconContainers[i].style.height = "auto";
        iconContainers[i].style.boxShadow = "none";
      }
    }
  }, [logo, handleClickIcon]);

  return (
    <div>
      <ChatFrame
        chatbox={
          <div>
            <ChatBox
              onSendMessage={handleOnSendMessage}
              userId={1}
              messages={attr.messages}
              width={"300px"}
              showTypingIndicator={true}
              activeAuthor={
                loading
                  ? {
                      username: name || "Ncc chatbot",
                      id: 2,
                      avatarUrl: botUrl, // Use React component for bot avatar
                    }
                  : null
              }
            />
            <ChatBoxFooter />
          </div>
        }
        icon={
          logo?.imgSrc ? (
            <img
              src={logo?.imgSrc}
              alt="Chat Icon"
              style={{
                maxWidth: "200px",
                maxHeight: "400px",
                cursor: "pointer",
              }}
              onClick={handleClickIcon}
            />
          ) : (
            <SiChatbot
              style={{
                fontSize: "2rem",
                marginLeft: "15px",
                color: "white",
                cursor: "pointer",
              }}
              onClick={handleClickIcon}
            />
          )
        }
        clickIcon={handleClickIcon}
        showChatbox={attr.showChatbox}
        showIcon={attr.showIcon}
        iconContainerClass={
          logo?.imgSrc
            ? "react-chat-frame-icon-container-no-background"
            : "react-chat-frame-icon-container"
        }
      >
        {greetingsText && (
          <div className="Greeting" style={{ width: "300px" }}>
            {greetingsText}
          </div>
        )}
      </ChatFrame>
    </div>
  );
};

export default Chat;
