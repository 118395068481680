import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Chat from "../components/Chatbox";
import axios from "axios";
import { BASE_URL } from "../constants";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Chatbot() {
  const [loading, setLoading] = useState(false);
  const [chatbot, setChatbot] = useState(null);

  const query = useQuery();
  const chatbotId = query.get("chatbot");
  const domain = query.get("domain");

  const fetchChatbotDetails = async (chatbotId) => {
    if (!chatbotId) return;
    setLoading(true);
    try {
      const response = await axios.post(BASE_URL + "/public/chatbot", {
        chatbotId,
        domain,
      });
      setChatbot(response.data);
    } catch (error) {
      console.log(" fetch error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (chatbotId) {
      fetchChatbotDetails(chatbotId);
    }
  }, [chatbotId]);

  return (
    <div>
      {!loading && chatbot && (
        <Chat chatbotId={chatbotId} chatbotData={chatbot} domain={domain} />
      )}
    </div>
  );
}
